import Analytics from './analytics.js';
import { page } from './analytics.config.js';

export default class PDPAnalytics extends Analytics {
  init() {
    this.setRefs();
    if (!this.bffPdp) {
      this.pushAnalytics(this.getAnalyticsData());
    }
  }

  setRefs() {
    this.pageName = this.el.dataset.pageTitle;
    this.country = document.documentElement.lang;
    this.price = this.el.dataset.productPrice;
    this.id = this.el.dataset.productId;
    this.bffPdp = document.querySelector(page.selectors.bffPdp);
  }

  getAnalyticsData() {
    return {
      event: page.events.viewItem,
      ecommerce: {
        items: [
          {
            item_name: this.pageName, // Name or ID is required.
            item_id: this.id,
            price: this.price,
            item_brand: null, // we need some kind of filed in page product properties to get that
            item_category: null, // ej: 'epen'
            item_category_2: null,
            item_category_3: null,
            item_category_4: null,
            item_variant: null, // ej: 'Gray'
            item_list_name: null, // ej: 'vype ePen' | If associated with a list selection.
            item_list_id: null, // ej: 'SR123' | If associated with a list selection.
            index: null, // ej: '2' | If associated with a list selection.
            quantity: '1',
          },
        ],
      },
    };
  }
}
