import { getCookie, decodeJWT } from 'commons/utils';
import Analytics from './analytics.js';
import { page } from './analytics.config.js';

export default class PageAnalytics extends Analytics {
  init() {
    this.setRefs();
    this.pushAnalytics(this.getAnalyticsData());
  }

  setRefs() {
    const countryCodeMatch = window.location.pathname.match(/\/([a-z]{2})\//i);
    this.country = countryCodeMatch ? countryCodeMatch[1] : null;

    switch (this.el.dataset.pageType) {
      case 'product':
        this.pageType = 'product detail';
        break;
      case 'product-list':
        this.pageType = 'product list';
        break;
      case 'blog-article':
        this.pageType = 'blog';
        break;
      default:
        this.pageType = this.el.dataset.pageTitle;
    }
    this.authCookieValue = getCookie(this.el.dataset.authCookie);
    if (this.authCookieValue) {
      this.jwt = decodeJWT(this.authCookieValue);
    }
    window.analyticsBrand = this.el.dataset.analyticsBrand;
  }

  getAnalyticsData() {
    return {
      event: page.events.allPages,
      UserID: this.jwt ? this.jwt.payload.uid : null,
      PageType: this.pageType,
      SystemType: null,
      LoggedInStatus: this.authCookieValue ? 'logged in' : 'logged out',
      Country: this.country,
      SiteSection: this.pageType,
      ServerSideTimestamp: Date.now(),
    };
  }
}
