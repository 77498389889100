import { getCookie, setCookie } from 'commons/utils';
import Analytics from './analytics.js';
import { page } from './analytics.config.js';

export default class AdobeDataLayerPageView extends Analytics {
  init() {
    this.setRefs();
    // push only after page load is measured
    this.measurePageLoadTime().then(duration => {
      this.prepareAndPushDataLayer(duration);
    }).catch(error => error);
    this.getUserData();
    this.getCommerceData();
  }

  setRefs() {
    this.pageType = this.el.dataset.pageTitle;
    this.country = document.documentElement.lang;
    this.basketElement = document.querySelector('.batcom-minibasket');
    this.basketCanadaElement = document.querySelector('.batcom-canada-minibasket');
    let config = {};
    if (this.basketCanadaElement) {
      config = JSON.parse(this.basketCanadaElement.dataset.ncParamsCanadaminibasket);
    }
    if (this.basketElement) {
      config = JSON.parse(this.basketElement.dataset.ncParamsMinibasket);
    }
    this.config = { ...this.config, ...config };
  }

  async getCommerceData() {
    const commerceData = getCookie('e-commerce') ? JSON.parse(getCookie('e-commerce')) : {};
    this.userCommerceData = commerceData?.ecommerce?.cart || {};
  }

  getPageData(duration) {
    const [language, countrySign] = document.documentElement.lang.split("-");
    const country = this.getCountry(language, countrySign.toUpperCase());
    return {
      country: country.toLowerCase(),
      language,
      name: document.title,
      timestamp: Date.now(),
      loadTime: duration,
    }
  }

  getCountry(lang, countryCode) {
    try {
      const regionNames = new Intl.DisplayNames([lang], {type: 'region'});
      return regionNames.of(countryCode);
    } catch (error) {
      return 'Invalid input';
    }
  }

  async measurePageLoadTime() {
    return new Promise((resolve, reject) => {
      window.addEventListener('load', () => {
        try {
          performance.mark('pageEnd');
          performance.measure('pageLoadTime', 'navigationStart', 'pageEnd');
          const measure = performance.getEntriesByName('pageLoadTime')[0];
          resolve(measure.duration);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  async getUserData() {
    const loginToken = getCookie('commerce-auth-token');
    const userData = {
      loginStatus: !!loginToken,
      type: this.getVisitorState(),
      province: getCookie('regionCode'),
    };
    const customerProfileCookie = getCookie('customerProfile');
    const advancedUserData = customerProfileCookie ? JSON.parse(customerProfileCookie) : {};

    this.userData = {...userData, ...advancedUserData};
  }

  getVisitorState() {
    const cookieValue = getCookie('firstTimeVisited');
    let visitor = 'New';
    if (cookieValue) {
      const firstTime = new Date(cookieValue);
      const today = new Date();
      if (firstTime.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
        visitor = 'Returning';
      }
    } else {
      setCookie('firstTimeVisited', this.getDateStamp(), 365);
    }
    return visitor;
  }

  getDateStamp() {
    const date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  prepareAndPushDataLayer(duration) {
    const dataLayer = {
      event: page.events.defaultPageView,
      page: this.getPageData(duration),
      user: this.userData,
      ecommerce: {
        cart: this.userCommerceData,
      },
    };

    this.push2AdobeDataLayer(dataLayer);
  }
}
