import { register } from '@netcentric/component-loader';
import PageAnalytics from 'commons/libs/analytics/analytics.page.js';
import AdobeDataLayerPageView from 'commons/libs/analytics/adobedatalayer.pageview.js';
import PDPAnalytics from 'commons/libs/analytics/analytics.productDetail.js';

class Page {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {
    this.pageAnalytics = new PageAnalytics(this.el);
    if (this.isProductPage()) {
      this.pdpAnalytics = new PDPAnalytics(this.el);
    }
    this.AdobeDataLayerPageView = new AdobeDataLayerPageView(this.el);
  }

  isProductPage() {
    return this.el.dataset.pageType === 'product';
  }
}

register({ Page });
